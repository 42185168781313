import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Form } from 'src/app/core/interfaces/form.interface';

@Injectable({
	providedIn: 'root',
})
export class MailService {
	private apiKey = 'hdJcQ5Zs-3iZB-hoOj-Zeqg-o4uS6Gvw6QIT'; //dev
	// private apiKey = '9b582e27-4f01-4537-9a3e-5c3b5a64ff62'; //prod
	private headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'api-key': this.apiKey,
	});

	private base64Escritura: string = '';
	private base64Logo: string = '';

	constructor(private http: HttpClient) {}

	sendMailFormatted(dataForm: Form): Observable<any> {
		let initHtmlContent = `
      <!DOCTYPE html>
      <html lang="es">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Alta de empresa en Pensumo</title>
      </head>
        <body>
          Hola Support,<br>
          A continuación se procede a mostrar la información recogida en el formulario de alta en Pensumo para su alta:

          <h1>Datos</h1>
          <h3>General</h3>
           <table cellpadding="5" cellspacing="0" style="border: 1px solid; border-collapse: collapse; width: 100%; table-layout: fixed; word-wrap: break-word;">
              <thead>
                <tr>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">País</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Nombre del comercio</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Página web</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Sector al que pertenece</th>
                  <th style="border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Descripción de la actividad</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm?.pais?.label}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.nombreComercio}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.web}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm?.sector?.label}</td>
                  <td style="text-align: left;">${dataForm.actividad}</td>
                </tr>
              </tbody>
            </table>
            <h3>Comercio adherido</h3>
            <table cellpadding="5" cellspacing="0" style="border: 1px solid; border-collapse: collapse; width: 100%; table-layout: fixed; word-wrap: break-word;">
              <thead>
                <tr>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Hostelería de España (o asociación dependiente de esta)</th>
                  <th style="border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">VOLVEREMOS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.hosteleriaAdherido !== '' ? 'SI' : 'NO'}</td>
                  <td style="text-align: left;">${dataForm.volvemosAdherido !== '' ? 'SI' : 'NO'}</td>
                </tr>
              </tbody>
            </table>
    `;

		let autonomoHtmlContent = `
            <h3>Detalles del autónomo</h3>
            <table cellpadding="5" cellspacing="0" style="border: 1px solid; border-collapse: collapse; width: 100%; table-layout: fixed; word-wrap: break-word;">
              <thead>
                <tr>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Nombre y apellidos</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">DNI</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Razón social</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Email profesional de contacto</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Teléfono profesional de contacto</th>
                  <th style="border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Dirección fiscal de facturación</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.nombreAutonomo}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.dniAutonomo}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.razonSocAutonomo}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.emailProfesional}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.telefonoProfesional}</td>
                  <td style="text-align: left;">${dataForm.direccionFiscal}</td>
                </tr>
              </tbody>
            </table>
    `;

		let sociedadHtmlContent = `
            <h3>Detalles de la sociedad</h3>
            <table cellpadding="5" cellspacing="0" style="border: 1px solid; border-collapse: collapse; width: 100%; table-layout: fixed; word-wrap: break-word;">
              <thead>
                <tr>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">NIF</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Razón social</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Email de notificaciones</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Forma de representación de la sociedad</th>
                  <th style="border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Inscripción de la Seguridad Social en el Registro Mercantil</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.nifSociedad}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.razonSocSociedad}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.emailSociedad}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm?.representacionSociedad?.label}</td>
                  <td style="text-align: left;">${dataForm.inscripcionSociedad}</td>
                </tr>
              </tbody>
            </table>
            <h3>Representante</h3>
            <table cellpadding="5" cellspacing="0" style="border: 1px solid; border-collapse: collapse; width: 100%; table-layout: fixed; word-wrap: break-word;">
              <thead>
                <tr>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Nombre y apellidos</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">DNI</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Email profesional de contacto</th>
                  <th style="border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Teléfono profesional de contacto</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.nombreRepresentante}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.dniRepresentante}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.emailRepresentante}</td>
                  <td style="text-align: left;">${dataForm.telefonoProfesionalRepresentante}</td>
                </tr>
              </tbody>
            </table>
            <h3>Escritura</h3>
            <table cellpadding="5" cellspacing="0" style="border: 1px solid; border-collapse: collapse; width: 100%; table-layout: fixed; word-wrap: break-word;">
              <thead>
                <tr>
                  <th style="border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Escritura</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">Se adjunta a este correo</td>
                </tr>
              </tbody>
            </table>
    `;

		let mancomunados = '';
		if (dataForm.mancomunados && dataForm.mancomunados.length > 0) {
			dataForm.mancomunados.forEach((mancomunado) => {
				mancomunados += `
          <tr>
            <td style="border-right: 1px solid; text-align: left;">${mancomunado.nombre}</td>
            <td style="border-right: 1px solid; text-align: left;">${mancomunado.dni}</td>
            <td style="border-right: 1px solid; text-align: left;">${mancomunado.email}</td>
            <td style="text-align: left;">${mancomunado.telefonoMancomunado}</td>
          </tr>`;
			});
		}

		let mancomunadosHtmlContent = `
            <h3>Mancomunados</h3>
            <table cellpadding="5" cellspacing="0" style="border: 1px solid; border-collapse: collapse; width: 100%; table-layout: fixed; word-wrap: break-word;">
              <thead>
                <tr>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Nombre y apellidos</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">DNI</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Email</th>
                  <th style="border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Teléfono</th>
                </tr>
              </thead>
              <tbody>
                ${mancomunados}
              </tbody>
            </table>
    `;

		let mandatoSepaHtmlContent = `
            <h3>Mandato SEPA B2B</h3>
            <table cellpadding="5" cellspacing="0" style="border: 1px solid; border-collapse: collapse; width: 100%; table-layout: fixed; word-wrap: break-word;">
              <thead>
                <tr>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Nombre del deudor</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Dirección del deudor</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Código Postal</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Población</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Provincia</th>
                  <th style="border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">País del deudor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.nombreDeudor}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.direccionDeudor}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.codigoPostalDeudor}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.poblacionDeudor}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.provinciaDeudor}</td>
                  <td style="text-align: left;">${dataForm.paisDeudor}</td>
                </tr>
              </tbody>
            </table>
            <br>
            <table cellpadding="5" cellspacing="0" style="border: 1px solid; border-collapse: collapse; width: 100%; table-layout: fixed; word-wrap: break-word;">
              <thead>
                <tr>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">SWIFT BIC</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">IBAN</th>
                  <th style="border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Localidad de la firma</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.SWIFT}</td>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.IBAN}</td>
                  <td style="text-align: left;">${dataForm.localidadFirma}</td>
                </tr>
              </tbody>
            </table>
    `;

		let logotipoHtmlContent = `
            <h3>Logotipo</h3>
            <table cellpadding="5" cellspacing="0" style="border: 1px solid; border-collapse: collapse; width: 100%; table-layout: fixed; word-wrap: break-word;">
              <thead>
                <tr>
                  <th style="border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Logotipo</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">Se adjunta a este correo</td>
                </tr>
              </tbody>
            </table>
    `;

		let promocionHtmlContent = `
            <hr>
            <h1>Promoción</h1>        
            <h3>Política de devoluciones</h3>
            <table cellpadding="5" cellspacing="0" style="border: 1px solid; border-collapse: collapse; width: 100%; table-layout: fixed; word-wrap: break-word;">
              <thead>
                <tr>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">¿Se permite devoluciones?</th>
                  <th style="border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Plazo de devolución</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.plazoDevolucion && dataForm.plazoDevolucion !== '' ? 'SI' : 'NO'}</td>
                  <td style="text-align: left;">${
																			dataForm.plazoDevolucion && dataForm.plazoDevolucion !== '' ? dataForm.plazoDevolucion : '-'
																		}</td>
                </tr>
              </tbody>
            </table>
            <h3>Aportación</h3>
            <table cellpadding="5" cellspacing="0" style="border: 1px solid; border-collapse: collapse; width: 100%; table-layout: fixed; word-wrap: break-word;">
              <thead>
                <tr>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Tipo de aportación</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Porcentaje de aportación por compra (%)</th>
                  ${
																			dataForm.tipoAportacion?.id === 'porcentaje_compra'
																				? ''
																				: dataForm.tipoAportacion?.id === 'porcentaje_compra_minimo'
																				? '<th style="border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Ticket mínimo de la compra</th>'
																				: '<th style="border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Máximo de aportación (€)</th>'
																		}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.tipoAportacion?.label}</td>
                  <td style="text-align: left;">${dataForm.aportacionCompra}</td>
                  ${
																			dataForm.tipoAportacion?.id === 'porcentaje_compra'
																				? ''
																				: dataForm.tipoAportacion?.id === 'porcentaje_compra_minimo'
																				? '<td style="text-align: left;">' + dataForm.aportacionCompraMinimoTicket + '</td>'
																				: '<td style="text-align: left;">' + dataForm.aportacionCompraMaximoTicket + '</td>'
																		}
                </tr> 
              </tbody>
            </table>
    `;

		let tiendaOnlineHtmlContent = `
            <hr>        
            <h1>Tiendas</h1>
            <h3>Tienda online</h3>
            <table cellpadding="5" cellspacing="0" style="border: 1px solid; border-collapse: collapse; width: 100%; table-layout: fixed; word-wrap: break-word;">
              <thead>
                <tr>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">URL de tu página web de venta</th>
                  ${
																			dataForm.tpvOnline && dataForm.tpvOnline !== ''
																				? `<th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">MerchantID o nº de comercio del TPV online</th>`
																				: ''
																		}
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Email de contacto</th>
                  <th style="border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Tipo de tienda</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.urlWebVenta}</td>
                  ${dataForm.tpvOnline && dataForm.tpvOnline !== '' ? `<td style="border-right: 1px solid; text-align: left;">${dataForm.tpvOnline}</td>` : ''}
                  <td style="border-right: 1px solid; text-align: left;">${dataForm.emailContactoWebVenta}</td>
                  <td style="text-align: left;">Tienda online</td>
                </tr>
              </tbody>
            </table>
    `;

		let establecimientos = '';
		if (dataForm.shops && dataForm.shops.length > 0) {
			dataForm.shops.forEach((shop: any) => {
				establecimientos += `
          <tr>
            <td style="border-right: 1px solid; text-align: left;">${shop.cells[0].label}</td>
            <td style="border-right: 1px solid; text-align: left;">${shop.cells[1].label}</td>
            <td style="border-right: 1px solid; text-align: left;">${shop.cells[2].label}</td>
            <td style="border-right: 1px solid; text-align: left;">${shop.cells[3].label}</td>
            <td style="border-right: 1px solid; text-align: left;">${shop.cells[4].label}</td>
            <td style="border-right: 1px solid; text-align: left;">${shop.cells[5].label}</td>
            <td style="text-align: left;">Tienda física</td>
          </tr>`;
			});
		}

		let establecimientosHtmlContent = `
            <hr>        
            <h1>Tiendas</h1>
            <h3>Establecimientos</h3>
            <table cellpadding="5" cellspacing="0" style="border: 1px solid; border-collapse: collapse; width: 100%; table-layout: fixed; word-wrap: break-word;">
              <thead>
                <tr>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Nombre</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Email</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Dirección</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Población</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Provincia</th>
                  <th style="border-right: 1px solid; border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Código postal</th>
                  <th style="border-bottom: 1px solid; background-color: #f4f4f4; text-align: left;">Tipo de tienda</th>
                </tr>
              </thead>
              <tbody>
              ${establecimientos}
              </tbody>
            </table>
    `;

		let finishHtmlContent = `
        <br><br><br><br>
        En caso de detectar algún problema con la información contenido en este correo, por favor, diríjase al administrador de la web del formulario.
        <br>Muchas gracias.
        </body>
      </html>
    `;

		let attachments: any = [];

		if (dataForm.businessType === 'Sociedad') {
			attachments.push(
				{
					filename: 'escritura.png',
					base64_content: dataForm.escritura ? this.base64Escritura.split('base64,')[1] : '',
					encoding: 'base64',
				},
				{
					filename: 'logotipo.png',
					base64_content: dataForm.logotipo ? this.base64Logo.split('base64,')[1] : '',
					encoding: 'base64',
				}
			);
		}

		return this.http.post(
			'/email',
			{
				subject: 'Prueba correo pensumo',
				from: 'noreply@dexga.com',
				fromName: 'Dexga Technologies',
				to: [
					{
						email: 'soporte-pensumo@tecalis.com',
						name: 'Support',
					},
					{
						email: 'josemaria.perez@tecalis.com',
						name: 'José María',
					},
				],
				attachments,
				html_content:
					initHtmlContent + // datos generales comunes
					(dataForm.businessType === 'Autonomo' ? autonomoHtmlContent : sociedadHtmlContent) + // datos concretos autonomo o sociedad
					(dataForm.mancomunados && dataForm.mancomunados.length > 0 ? mancomunadosHtmlContent : '') + // mancomunados sociedad (si hay)
					mandatoSepaHtmlContent + // mandato sepa, comun a ambos
					(dataForm.businessType !== 'Autonomo' ? logotipoHtmlContent : '') + // logotipo sociedad
					promocionHtmlContent + // step2: promociones
					(dataForm.channel === 'CANAL ONLINE' ? tiendaOnlineHtmlContent : dataForm.channel === 'CANAL ONLINE Y FISICO' ? tiendaOnlineHtmlContent + establecimientosHtmlContent : establecimientosHtmlContent) + //step3: tiendas
					finishHtmlContent, // cierre html
			},
			{ headers: this.headers }
		);
	}

	convertToBase64(file: File, type: string): void {
		const reader = new FileReader();

		reader.onload = () => {
			if (type === 'escritura') this.base64Escritura = reader.result as string;
			if (type === 'logo') this.base64Logo = reader.result as string;
		};

		reader.readAsDataURL(file);
	}
}
