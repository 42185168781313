import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { LanguageService } from './core/translate/language.service';
import { CookiesService } from './features/services/cookies.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	languageOptions: any[] = [
		{ label: 'ES', id: 'es' },
		{ label: 'EN', id: 'en' },
	];
	language: object | undefined = {};

	showModal: boolean = false;
	showCookies: boolean;
	infoCookies: string = `Te informamos que en este sitio web, titularidad de Pensumo, Pensión por Consumo, S.L., se utilizan cookies propias técnicas que permiten el acceso a la navegación y a los servicios que se ofrecen en el sitio web; cookies propias de personalización que, si lo autorizas, recordarán tus preferencias; cookies propias gestionadas por terceros que, si lo autorizas, analizarán tu navegación con fines estadísticos; y cookies propias gestionadas por terceros y cookies de terceros que, si lo autorizas, recordarán tus preferencias mientras navegas y recabarán información sobre tu comportamiento, con el fin de desarrollar un perfil específico sobre ti y mostrarte publicidad en función del mismo. <br>Puedes aceptar el uso de todas las cookies pulsando el botón "ACEPTAR", rechazarlas pulsando "RECHAZAR" o configurar su uso pulsando "CONFIGURAR". <a>Para conocer más información, visita nuestra Política de cookies.</a>`;

	constructor(
		public location: Location,
		private readonly languageService: LanguageService,
		private readonly cookiesService: CookiesService
	) {
		this.languageService.autoResolveLanguage();
		if (
			this.cookiesService.getCookie() &&
			(this.cookiesService.getCookie() === 'true' || this.cookiesService.getCookie() === 'false')
		)
			this.showCookies = false;
		else this.showCookies = true;
	}

	setCookies(event: any) {
		switch (event) {
			case 'aceptar':
				this.showCookies = false;
				this.cookiesService.setCookie(true);
				break;
			case 'rechazar':
				this.showCookies = false;
				this.cookiesService.setCookie(false);
				break;
			default:
				break;
		}
	}

	modalSelection(event: any) {
		switch (event) {
			//Aceptar
			case 'buttonPrimary':
				this.showModal = this.showCookies = false;
				this.cookiesService.setCookie(true);
				break;
			//Cancelar
			case 'buttonSecondary':
				this.showModal = false;
				break;
			//Cruz		
			default:
				this.showModal = false;
				break;
		}
	}
}
