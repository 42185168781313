<tec-base>
	<tec-topnav
		*ngIf="
			location.path().includes('registration') || location.path().includes('resume') || location.path().includes('finish')
		"
		[img]="'assets/images/pensumo-logo.svg'"
	>
		<tec-link [href]="'https://cdn.ibercaja.net/pensumo/comercios/informacion-comercios_v1.pdf'" target="_blank"
			>Sobre Pensumo</tec-link
		>
		<tec-link [href]="'https://cdn.ibercaja.net/pensumo/comercios/faqs-comercios_v1.pdf'" target="_blank">FAQ's</tec-link>
		<tec-link [href]="'mailto:soportecomercios@pensumo.es'">Ayuda</tec-link>
	</tec-topnav>
	<router-outlet></router-outlet>
	<!-- <tec-cookies [text]="infoCookies" *ngIf="showCookies">
		<tec-link (click)="showModal = true">Configurar</tec-link>
		<tec-button
			[label]="'Rechazar'"
			[type]="'secondary'"
			[size]="'default'"
			(clickEvent)="setCookies('rechazar')"
		></tec-button>
		<tec-button
			[label]="'Aceptar'"
			[type]="'default'"
			[size]="'default'"
			(clickEvent)="setCookies('aceptar')"
		></tec-button>
	</tec-cookies> -->
</tec-base>
<tec-modal
	[isVisible]="showModal"
	title="Configuración de cookies"
	buttonSecondaryLabel="Cancelar"
	buttonPrimaryLabel="Confirmar"
	(closeEvent)="modalSelection($event)"
>
	<ng-container body> Lorem ipsum dolor sit amet, consectetur adipiscing elit. </ng-container>
</tec-modal>
