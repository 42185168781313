import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  private pensumoCookie: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public pensumoCookie$: Observable<string> = this.pensumoCookie.asObservable();

  constructor(private cookieService: CookieService) { }

  setCookie(val: boolean) {
    const expirationDate = new Date();
		expirationDate.setFullYear(expirationDate.getFullYear() + 100);

    if (!!val) {
			this.cookieService.set('pensumoCookies', 'true', {
				expires: expirationDate,
				path: '/',
				secure: true,
			});
      this.pensumoCookie.next('true');

		} else {
			// this.cookieService.delete('pensumoCookies');
			this.cookieService.set('pensumoCookies', 'false', {
				expires: expirationDate,
				path: '/',
				secure: true,
			});
      this.pensumoCookie.next('false');
		}
  }

  getCookie(): string {
    return this.cookieService.get('pensumoCookies');
  }
}
